@use "@/styles/abstracts" as *;

.input-wrapper {
	position: relative;
}

.input {
	$input: &;
	@include input-style;
}

textarea.input {
	resize: vertical;
}
