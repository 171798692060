@use "@/styles/abstracts" as *;

.field {
	display: grid;
	align-content: flex-start;
	gap: rem(6);

	@include breakpoint($lg) {
		gap: rem(8);
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-wrap: wrap;
	}

	&__label {
		@include label-style;

		&--hidden {
			@include hide;
		}
	}

	&__non-asterisk {
		align-self: flex-end;
		// color: #{$color-invalid};
	}

	&--error {
		--error-color: var(--theme--invalid-color);
	}

	&__error {
		display: grid;
		font-weight: $fw-medium;
		font-size: rem(16);
		line-height: rem(24);
		color: var(--error-color);

		p {
			margin: 0;
		}
	}
}
