@use "../variables/" as *;
@use "../functions/" as *;
@use "./breakpoint" as *;

@mixin h1 {
	font-family: $font-headings;
	font-weight: $fw-black;
	font-size: rem(64);
	line-height: rem(66);

	@include breakpoint($md) {
		font-size: rem(80);
		line-height: rem(82);
	}

	@include breakpoint($lg) {
		font-size: rem(88);
		line-height: rem(90);
	}
}

@mixin h2 {
	font-family: $font-headings;
	font-weight: $fw-black;
	font-size: rem(56);
	line-height: rem(58);

	@include breakpoint($md) {
		font-size: rem(64);
		line-height: rem(66);
	}

	@include breakpoint($lg) {
		font-size: rem(80);
		line-height: rem(88);
	}
}

@mixin h3 {
	font-family: $font-headings;
	font-weight: $fw-black;
	font-size: rem(48);
	line-height: rem(50);

	@include breakpoint($md) {
		font-size: rem(56);
		line-height: rem(58);
	}

	@include breakpoint($lg) {
		font-size: rem(64);
		line-height: rem(66);
	}
}

@mixin h4 {
	font-family: $font-headings;
	font-weight: $fw-black;
	font-size: rem(32);
	line-height: rem(34);

	@include breakpoint($md) {
		font-size: rem(48);
		line-height: rem(50);
	}
}

@mixin h5 {
	font-family: $font-headings;
	font-weight: $fw-black;
	font-size: rem(24);
	line-height: rem(32);

	@include breakpoint($md) {
		font-size: rem(28);
		line-height: rem(36);
	}

	@include breakpoint($lg) {
		font-size: rem(32);
		line-height: rem(36);
	}
}

@mixin pre-heading {
	font-family: $font-stack;
	font-size: rem(16);
	line-height: rem(16);
	font-weight: 700;
	letter-spacing: rem(1);
	text-transform: uppercase;

	@include breakpoint($lg) {
		font-size: rem(18);
		line-height: rem(18);
	}
}
@mixin body-1 {
	font-family: $font-stack;
	font-size: rem(20);
	line-height: rem(24);

	@include breakpoint($lg) {
		font-size: rem(22);
		line-height: rem(26);
	}
}
@mixin body-2 {
	font-family: $font-stack;
	font-size: rem(18);
	line-height: rem(24);
}
@mixin body-3 {
	font-family: $font-stack;
	font-size: rem(16);
	line-height: rem(20);
}
