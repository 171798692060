@use "../variables" as *;

// Apply a pseudo-element background, with a negative margin offset
@mixin pseudo-background($bg, $pseudo: "before") {
	z-index: 0;
	&::#{$pseudo} {
		content: "";
		pointer-events: none;
		position: absolute;
		border-radius: inherit;
		background: $bg;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		transition: background $timing-short $ease-std;

		@content;
	}
}

// Apply a pseudo-element border, with a negative margin offset
@mixin pseudo-border(
	$style,
	$radius: inherit,
	$offsetT: 0,
	$offsetR: $offsetT,
	$offsetB: $offsetT,
	$offsetL: $offsetR,
	$pseudo: "before"
) {
	&::#{$pseudo} {
		content: "";
		pointer-events: none;
		position: absolute;
		border: $style;
		border-radius: $radius;
		left: -$offsetL;
		right: -$offsetR;
		top: -$offsetT;
		bottom: -$offsetB;
		z-index: 0;
		transition: border $timing-short $ease-std;

		@content;
	}
}

@mixin pseudo-border-dashed($size, $weight, $color, $radius: 0px) {
	overflow: hidden;
	position: relative;

	&::before {
		content: "";
		pointer-events: none;
		position: absolute;
		top: -$size + $weight;
		bottom: -$size + $weight;
		left: -$size + $weight;
		right: -$size + $weight;
		border-radius: calc(#{$radius} + (#{$size} - #{$weight}));
		border: $size dashed $color;
	}
}

@mixin link-cover($offset: 0) {
	&::before {
		content: "";
		position: absolute;
		top: -$offset;
		left: -$offset;
		right: -$offset;
		bottom: -$offset;
		z-index: 1;
		@content;
	}
}

@mixin image-hero-fades {
	// Top Fade
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 215px;
		z-index: -2;
		background: linear-gradient(to top, transparent, black);
		opacity: 0.6;
	}
	// Left Fade
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		z-index: -2;
		height: 100%;
		width: 100%;
		background: linear-gradient(107deg, black 30%, transparent 60%);
		opacity: 0.4;
	}
}
