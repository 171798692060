@use "@/styles/abstracts" as *;

.checkbox {
	$input: &;
	display: flex;
	align-items: center;
	gap: rem(16);
	cursor: pointer;
	position: relative;
	touch-action: manipulation;

	&:hover &__input {
		border-color: var(--theme--input-focus-border-accent);
	}

	&__input {
		align-self: flex-start;
		cursor: pointer;
		flex: none;
		color: inherit;
		appearance: none;
		font-size: rem(32);
		width: 1em;
		height: 1em;
		display: block;
		border: 2px solid var(--error-color, currentColor);
		border-radius: 10px;

		&:checked {
			color: var(--theme--button-fill-accent);
			background-color: currentColor;
			background-image: svg-encode(
				'<svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.73722 9.15L14.2122 0.675C14.4122 0.475 14.6497 0.375 14.9247 0.375C15.1997 0.375 15.4372 0.475 15.6372 0.675C15.8372 0.875 15.9372 1.1125 15.9372 1.3875C15.9372 1.6625 15.8372 1.9 15.6372 2.1L6.43722 11.3C6.23722 11.5 6.00389 11.6 5.73722 11.6C5.47055 11.6 5.23722 11.5 5.03722 11.3L0.737219 7C0.537219 6.8 0.441385 6.5625 0.449719 6.2875C0.458052 6.0125 0.562219 5.775 0.762219 5.575C0.962219 5.375 1.19972 5.275 1.47472 5.275C1.74972 5.275 1.98722 5.375 2.18722 5.575L5.73722 9.15Z" fill="white"/></svg>'
			);
			background-repeat: no-repeat;
			background-position: center;
		}

		&:focus-visible {
			outline: 3px dashed var(--theme--input-focus-border-accent);
			outline-offset: 4px;
		}
	}

	&__label {
		@include body-2;
		font-weight: $fw-medium;
		color: var(--error-color, currentColor);
	}
}
