@use "@/styles/abstracts" as *;

.fieldset {
	$fieldset: &;
	display: grid;
	align-items: flex-start;

	&__legend {
		@include h5;
	}

	&__asterisk {
		color: $color-invalid;
	}

	&__inner {
		@include form-style;
	}
}
