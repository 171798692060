@use "../variables" as *;

@mixin container($width) {
	max-width: calc($width + (var(--container-gutter) * 2));
	padding-left: var(--container-gutter);
	padding-right: var(--container-gutter);
	margin-left: auto;
	margin-right: auto;
	width: 100%;
}

@mixin container-full() {
	width: 100%;
	padding-left: var(--container-gutter);
	padding-right: var(--container-gutter);
}
