@use "../variables" as *;

@mixin theme-dawn {
	--theme--background: #{$color-orange--light};
	--theme--text-color: #{$color-purple--dark};
	--theme--button-color: #{$color-purple--dark};
	--theme--input-focus-border-accent: #{$color-orange--500};
	--theme--input-hover-border-accent: #{$color-orange--300};
	--theme--button-fill-accent: #{$color-orange--primary};
	--theme--button-border-accent: #{$color-purple--dark};
	--theme--button-active-fill-accent: #{$color-orange--700};
	--theme--button-active-border-accent: #{$color-orange--primary};
	--theme--button-disabled-fill: #{$color-orange--200};
	--theme--button-disabled-color: #{$color-orange--400};
	--theme--select-border-accent: var(--theme--input-focus-border-accent);
	--theme--highlight-color: #{$color-orange--primary};
	--theme--card-bg: #{$color-white};
	--theme--invalid-color: #{$color-invalid};
	--theme--file-upload-border: #{$color-orange--400};
	--theme--file-upload-bg: #{$color-white};

	::selection {
		background-color: #{$color-orange--200};
		color: #{$color-orange--800};
	}
}

@mixin theme-dusk {
	--theme--background: #{$color-purple--primary};
	--theme--text-color: #{$color-white};
	--theme--button-color: #{$color-white};
	--theme--input-focus-border-accent: #{$color-purple--800};
	--theme--input-hover-border-accent: #{$color-purple--300};
	--theme--button-fill-accent: #{$color-purple--dark};
	--theme--button-border-accent: #{$color-white};
	--theme--button-active-fill-accent: #{$color-purple--800};
	--theme--button-active-border-accent: #{$color-purple--800};
	--theme--button-disabled-fill: #{$color-purple--300};
	--theme--button-disabled-color: #{$color-purple--500};
	--theme--select-border-accent: var(--theme--input-focus-border-accent);
	--theme--highlight-color: #{$color-purple--dark};
	--theme--card-bg: #{$color-orange--light};
	--theme--invalid-color: #{$color-orange--300};
	--theme--file-upload-border: #{$color-purple--200};
	--theme--file-upload-bg: #{$color-purple--700};

	::selection {
		background-color: #{$color-purple--700};
		color: #{$color-purple--100};
	}
}

@mixin theme-midnight {
	--theme--background: #{$color-purple--dark};
	--theme--text-color: #{$color-white};
	--theme--button-color: #{$color-white};
	--theme--input-focus-border-accent: #{$color-orange--500};
	--theme--input-hover-border-accent: #{$color-orange--300};
	--theme--button-fill-accent: #{$color-orange--primary};
	--theme--button-border-accent: #{$color-white};
	--theme--button-active-fill-accent: #{$color-orange--700};
	--theme--button-active-border-accent: #{$color-orange--primary};
	--theme--button-disabled-fill: #{$color-orange--200};
	--theme--button-disabled-color: #{$color-orange--400};
	--theme--select-border-accent: var(--theme--input-focus-border-accent);
	--theme--highlight-color: #{$color-orange--primary};
	--theme--card-bg: #{$color-orange--light};
	--theme--invalid-color: #{$color-orange--400};
	--theme--file-upload-border: #{$color-purple--400};
	--theme--file-upload-bg: #{$color-purple--800};

	::selection {
		background-color: #{$color-purple--800};
		color: #{$color-purple--100};
	}
}
