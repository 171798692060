@mixin hide {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px !important;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    display: block;
}