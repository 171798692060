@use "@/styles/abstracts" as *;

.select-trigger {
	$trigger: &;
	margin: 0;
	padding: 0;
	appearance: none;
	border: none;
	cursor: pointer;
	@include input-style;
	text-align: left;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: rem(12);

	&[data-state="open"] {
		border-color: var(--theme--select-border-accent);
	}

	&[data-placeholder] {
		#{$trigger}__value {
			color: $color-grey--600;
		}
	}

	&__value {
		pointer-events: none;
	}

	&__icon {
		flex: none;
		width: rem(14);
		height: auto;
		color: var(--theme--select-border-accent);
	}
}

.select-content {
	@include dropdown-style;

	&[data-side="top"] {
		border-radius: 10px 10px 0 0;
		&::before {
			top: 100%;
		}
	}

	&[data-side="bottom"] {
		border-radius: 0 0 10px 10px;
		&::before {
			bottom: 100%;
		}
	}

	&__item {
		@include dropdown-item-style;
		&[data-highlighted] {
			@include dropdown-item-highlighted-style;
		}
	}
}
