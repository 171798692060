@use "@/styles/abstracts" as *;

.form-block {
	scroll-margin-top: var(--header-height);
	padding: rem(60) 0;

	@include breakpoint($md) {
		padding: rem(80) 0;
	}

	@include breakpoint($lg) {
		padding: rem(96) 0;
	}

	&--dawn {
		@include theme-dawn;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--dusk {
		@include theme-dusk;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}
	&--midnight {
		@include theme-midnight;
		background-color: var(--theme--background);
		color: var(--theme--text-color);
	}

	&__wrapper {
		@include container($container-std);
	}

	&__form {
		display: grid;
		gap: rem(32);
		@include breakpoint($md) {
			gap: rem(48);
		}
		@include breakpoint($lg) {
			gap: rem(64);
		}
	}

	&__fieldset {
		@include form-style;
		appearance: none;
		padding: none;
		border: none;
		legend {
			display: block;
			@include h5;
		}
	}

	&__submit {
		justify-self: flex-start;
	}

	&__success {
		h2 {
			@include h1;
			margin: 0;
		}
		p {
			@include body-1;
		}
	}
}
