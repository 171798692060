@use "../functions" as *;
@use "../variables" as *;
@use "./type" as *;
@use "./breakpoint" as *;
@use "./pseudo" as *;

@mixin form-style {
	display: grid;
	gap: rem(20);
	@include breakpoint($md) {
		gap: rem(28);
	}
	@include breakpoint($lg) {
		gap: rem(36);
	}
}

@mixin label-style {
	@include body-2;
	font-weight: $fw-bold;
}

@mixin input-style {
	width: 100%;
	color: $color-text;
	appearance: none;
	background-color: $color-grey--100;
	border: 2px solid var(--error-color, #{$color-grey--200});
	border-radius: 10px;
	padding-block: rem(8);
	padding-left: calc(#{rem(12)} + var(--input-left, 0px));
	padding-right: var(--input-right, #{rem(12)});
	font-size: rem(16);
	line-height: 1.5;

	@include breakpoint($md) {
		padding-block: rem(12);
		padding-left: calc(#{rem(16)} + var(--input-left, 0px));
		padding-right: var(--input-right, #{rem(16)});
	}

	@include breakpoint($lg) {
		padding-block: rem(16);
	}

	&::placeholder {
		color: $color-grey--600;
	}

	&:hover {
		border-color: var(--theme--input-hover-border-accent);
	}

	&:focus {
		outline: none;
		border-color: var(--theme--input-focus-border-accent);
	}

	&:disabled {
		background-color: transparent;
		border-color: $color-grey--600;
		pointer-events: none;
		&::placeholder {
			color: $color-grey--600;
		}
	}
}

@mixin textarea-style {
	@include input-style;
}

@mixin help-style {
	// font-size: rem(12);
	// font-weight: $fw-medium;
	// line-height: rem(16);
	// color: rgba(0, 0, 0, 0.6);
}

@mixin dropdown-style {
	width: var(--radix-select-trigger-width, 100%);
	background-color: $color-grey--100;
	border: 2px solid var(--theme--select-border-accent);
	color: $color-text;
	padding: 0;
	z-index: 99999;

	// Non-rounded bridge
	&::before {
		content: "";
		position: absolute;
		left: 0;
		width: 100%;
		height: 10px;
		background-color: $color-white;
		border-left: inherit;
		border-right: inherit;
	}

	border-radius: 0 0 10px 10px;
	&::before {
		bottom: 100%;
	}
}

@mixin dropdown-item-style {
	width: 100%;
	@include body-2;
	padding: rem(8) rem(12);
	font-size: rem(16);
	line-height: 1.5;
	user-select: none;

	@include breakpoint($md) {
		padding: rem(8) rem(16);
	}
	@include breakpoint($lg) {
		padding: rem(16);
	}
}

@mixin dropdown-item-highlighted-style {
	outline: none;
	position: relative;
	border-radius: 6px;
	@include pseudo-background($color-grey--200) {
		top: 4px;
		left: 4px;
		right: 4px;
		bottom: 4px;
	}
}
