@use "@/styles/abstracts" as *;

.checkbox-group {
	padding-top: rem(16);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: rem(12);

	@include breakpoint($sm) {
		gap: rem(18) rem(40);
	}

	@include breakpoint($md) {
		gap: rem(20) rem(48);
	}

	&--hidden-label {
		padding-top: 0;
	}

	&__help {
		display: block;
		font-size: 0.8em;
		opacity: 0.8;
		width: 100%;
		margin-top: 0.5em;
	}
}
