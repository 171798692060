// Brand Colors
// --------------------------------------------------
$color-black: #000;
$color-white: #fff;

$color-orange--100: #f9e9dc;
$color-orange--200: #faccbe;
$color-orange--300: #fbaea0;
$color-orange--400: #fd9183;
$color-orange--500: #fe7365;
$color-orange--600: #ff5647;
$color-orange--700: #d74336;
$color-orange--800: #a63228;
$color-orange--900: #5e211b;
$color-orange--primary: $color-orange--600;
$color-orange--light: $color-orange--100;

$color-purple--100: #e9ddff;
$color-purple--200: #d5bfff;
$color-purple--300: #c2a1ff;
$color-purple--400: #ae83fe;
$color-purple--500: #9a65fe;
$color-purple--600: #9646ff;
$color-purple--700: #6f2fc8;
$color-purple--800: #471890;
$color-purple--900: #200158;
$color-purple--primary: $color-purple--600;
$color-purple--dark: $color-purple--900;

$color-grey--100: $color-white; // Holdover from old site, bad pattern
$color-grey--200: #ededf0;
$color-grey--300: #dcdbe0;
$color-grey--400: #cac9d1;
$color-grey--500: #b9b7c1;
$color-grey--600: #a7a5b2;
$color-grey--700: #6f6e77;
$color-grey--800: #38373b;
$color-grey--900: $color-black; // Holdover from old site, bad pattern

$color-warning: #f8ee53;

$color-text: $color-purple--dark;

$color-invalid: #d9252a;
