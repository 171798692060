@mixin breakpoint($min: 0, $max: 0, $side: "width") {
    // check to see if first variable is string or number
    $type: type-of($min);
    $sideType: type-of($side);

    // if it's a number, check if min, max or both are required.
    // then check if the values of $side are correctly spelt 'width' or 'height'
    // write new breakpoint using these boundaries.
    // default to 'all' if nothing specified.
    @if $type == number and $sideType == string {
        @if $side == "width" or $side == "height" {
            $query: "all" !default;
            @if $min != 0 and $max != 0 {
                $query: "(min-#{$side}: #{$min}) and (max-#{$side}: #{$max})";
            } // set both min and max
            @else if $min != 0 and $max == 0 {
                $query: "(min-#{$side}: #{$min})";
            } // set just min
            @else if $min == 0 and $max != 0 {
                $query: "(max-#{$side}: #{$max})";
            } // set just max
            @media #{$query} {
                @content;
            }
        }
    }
}
