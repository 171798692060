@use "@/styles/abstracts" as *;

.file-upload-wrapper {
}

.file-upload {
	padding: rem(24) rem(40) rem(40);
	display: grid;
	gap: rem(16);
	text-align: center;
	justify-items: center;
	background: var(--theme--file-upload-bg);
	border-radius: 8px;

	@include pseudo-border-dashed(4px, 1px, var(--theme--file-upload-border), 8px);

	&--error {
		@include pseudo-border(1px solid var(--error-color, var(--theme--file-upload-border)));
	}

	&--dragging {
		@include pseudo-border(2px solid var(--error-color, var(--theme--file-upload-border)));
	}

	&::before {
		transition: none;
	}

	&__heading {
		@include body-1;
		font-weight: $fw-bold;

		button {
			font-weight: inherit;
			appearance: none;
			background: none;
			color: inherit;
			text-decoration: underline;
			border: none;
			margin: 0;
			padding: 0;
			display: inline;
			cursor: pointer;
		}
	}

	&__descriptions {
		display: grid;
		gap: rem(8);
		align-items: center;
	}
	&__description {
		@include body-2;
	}
}

.file-list {
	list-style: none;
	margin: 0;
	padding: 0;
	display: grid;
	gap: rem(16);
	margin-top: rem(16);
}

.file-item {
	@include body-3;
	@include breakpoint($md) {
		@include body-2;
	}
	background-color: $color-white;
	color: $color-text;
	display: flex;
	border-radius: 8px;
	align-items: center;
	padding: rem(16);
	gap: rem(16);
	border: 1px solid var(--error-color, #{$color-white});

	&__name {
		color: $color-text;
	}

	&__size {
		color: $color-grey--700;
	}

	&__delete {
		color: $color-invalid;
		padding: 0;
		background: none;
		border: none;
		appearance: none;
		margin-left: auto;
		height: rem(32);
		width: rem(32);
		font-size: rem(16);
		display: grid;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		> * {
			grid-area: 1/-1;
		}
	}
}
