@use "@/styles/abstracts" as *;

.radio-group {
	padding-top: rem(16);
	display: flex;
	flex-direction: column;
	gap: rem(12);

	@include breakpoint($sm) {
		flex-direction: row;
		flex-wrap: wrap;
		gap: rem(18) rem(40);
	}

	@include breakpoint($md) {
		gap: rem(20) rem(48);
	}

	&--hidden-label {
		padding-top: 0;
	}
}
